import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "reactstrap";
import { Assets, Layouts, Modals, StyledComponents } from "../components-config";
import { InputFile } from "./InputFile";
import * as digitalizationActions from "./store/digitalizationActions";
import { DropDownSelectSearch } from "./DropDownSelectSearch";

export const Information = () => {
  return (
    <>
      <Layouts.Info>
        <div>
          <StyledComponents.MyP fontSize="14px" className="font-weight-bold text-purple40">
            Hola, {localStorage.getItem("username")}.
          </StyledComponents.MyP>
          <StyledComponents.MyP fontSize="14px" className="text-purple40">
            Seleccione el nombre del proveedor del cual desea procesar documentos. A continuación,
            elija el archivo PDF desde su equipo utilizando el botón ‘Examinar’. Finalmente,
            presione ‘Procesar’ para iniciar la digitalización.
          </StyledComponents.MyP>
        </div>
      </Layouts.Info>
    </>
  );
};

export const Form = () => {
  const dispatch = useDispatch();
  const digitalizationReducer = useSelector((state) => state.digitalizationReducer);
  const [step, setStep] = useState({
    stepOneisComplete: false,
    stepTwoisComplete: false,
    stepThreeIsComplete: false,
  });
  const [fileNames, setFileNames] = useState([]);
  const [files, setFiles] = useState([]);
  const [isAlredyProcessed, setIsAlredyProcessed] = useState(false);
  const [supplier, setSupplier] = useState({});
  const [deliveryNumber, setDeliveryNumber] = useState("");
  const [purchaseOrder, setpurchaseOrder] = useState("");
  const [overflow, setOverflow] = useState(false);
  const [messageError, setMessageError] = useState({});
  const [viewError, setViewError] = useState(false);

  const {
    list_supliers,
    digitalization_action_success,
    api_actions: { cargando, isActionSuccess, error },
  } = digitalizationReducer;

  useEffect(() => {
    dispatch(digitalizationActions.digitalizationMethods({}, "GetDigitalizationSupliers"));
  }, []);

  useEffect(() => {
    if (
      Object.keys(supplier).length > 0 &&
      deliveryNumber.trim() !== "" &&
      purchaseOrder.trim() !== ""
    ) {
      setStep({ ...step, stepOneisComplete: true });
    } else {
      setFileNames([]);
      setFiles([]);
      setStep({
        ...step,
        stepOneisComplete: false,
        stepTwoisComplete: false,
        stepThreeIsComplete: false,
      });
      setViewError(false);
      setIsAlredyProcessed(false);
    }
  }, [supplier, deliveryNumber, purchaseOrder]);

  useEffect(() => {}, [isAlredyProcessed, isActionSuccess, error]);

  useEffect(() => {
    setOverflow(list_supliers.length > 10 ? true : false);
  }, [list_supliers]);

  function handlerChangeInputFile(info) {
    if (info.totalSizeMB <= 10 && info.fileCount == 1) {
      setFileNames(info.nameFiles);
      setFiles(info.files);
      setStep({ ...step, stepTwoisComplete: true, stepThreeIsComplete: false });
      setViewError(false);
    } else {
      setViewError(false);
      setFileNames(info.nameFiles);
      setFiles(info.files);
      setStep({ ...step, stepTwoisComplete: false, stepThreeIsComplete: false });
      if (info.fileCount > 1) {
        setViewError(true);
        setMessageError({ uploadPdf: ["Solo puedes cargar una factura en PDF a la vez."] });
      }
      if (info.totalSizeMB > 10) {
        setViewError(true);
        setMessageError({ uploadPdf: ["El archivo PDF no debe superar los 10 MB."] });
      }
    }
    setIsAlredyProcessed(false);
  }

  function handlerChangeSuplier(data) {
    if (data.id != "") {
      setSupplier(data);
    } else {
      setFileNames([]);
      setStep({
        ...step,
        stepOneisComplete: false,
        stepTwoisComplete: false,
        stepThreeIsComplete: false,
      });
    }
  }

  function handleClickProcess(e) {
    const data = {
      email: localStorage.getItem("email"),
      files: files,
      supplier: supplier.id,
      delivery_number: deliveryNumber,
      purchase_order: purchaseOrder,
    };
    dispatch(digitalizationActions.digitalizationMethods(data, "DigitalizationTransfer")).then(
      (data) => {
        setIsAlredyProcessed(true);
        setStep({ ...step, stepThreeIsComplete: true });
      }
    );
  }

  function handlerOnClickProcessOther() {
    setStep({
      stepOneisComplete: false,
      stepTwoisComplete: false,
      stepThreeIsComplete: false,
    });
    setFileNames([]);
    setIsAlredyProcessed(false);
    setDeliveryNumber("");
    setpurchaseOrder("");
  }

  function splitCamelCaseAndCapitalize(text) {
    return text
      .split(/(?=[A-Z])/)
      .join(" ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  }

  const renderErrors = (errors) => (
    <div style={{ fontSize: "12px" }} className={`text-danger`}>
      {Object.entries(errors).map(([key, messages]) => (
        <p key={key} className="font-weight-bold">
          - {splitCamelCaseAndCapitalize(key)}: {messages.join(", ")}
        </p>
      ))}
    </div>
  );

  const renderNotifications = (notifications) => (
    <div style={{ fontSize: "12px" }}>
      Revisa las notificaciones:
      {notifications.map((message, index) => (
        <p key={index} className="font-weight-bold">
          - {message}
        </p>
      ))}
    </div>
  );

  return (
    <>
      {cargando && (
        <Modals.ModalLoading message="Un momento, por favor, el documento está siendo procesado…" />
      )}

      <Information />
      <Layouts.Card
        margin="4px 0 0 0"
        padding="40px 20px"
        borderRadius="0 0 20px 20px"
        className="text-purple40">
        <StyledComponents.SpecialContainer>
          {/* Step 01 */}
          <StyledComponents.SpecialContainer className="d-flex">
            <StyledComponents.SpecialContainer className="d-flex" style={{ gap: "20px" }}>
              <StyledComponents.SpecialContainer
                className="l-flex flex-column"
                style={{ width: "460px" }}>
                <StyledComponents.MyP
                  fontSize="14px"
                  className="mb10 font-weight-bold text-purple40">
                  1. Indique el Proveedor, Delivery y Orden de Compra…
                </StyledComponents.MyP>
                <DropDownSelectSearch
                  dropdownTop="35px"
                  group={list_supliers}
                  onChange={handlerChangeSuplier}
                  width="460px"
                  overflow={overflow}
                  disabled={isAlredyProcessed && isActionSuccess}
                />
              </StyledComponents.SpecialContainer>

              <StyledComponents.SpecialContainer
                className="l-flex flex-column"
                style={{ width: "260px" }}>
                <StyledComponents.MyP
                  fontSize="14px"
                  className="mb10 font-weight-bold text-purple40 ml8">
                  Delivery:
                </StyledComponents.MyP>
                <StyledComponents.MyInput
                  value={deliveryNumber}
                  onChange={(e) => {
                    setDeliveryNumber(e.target.value);
                  }}
                  borderRadius="40px"
                  textAlign="left"
                  className={"bg-grey130"}
                  margin="0px 4px 0px"
                  placeholder="# Delivery"
                  fontSize="12px"
                  width="260px"
                  padding="10px"
                  disabled={isAlredyProcessed && isActionSuccess}></StyledComponents.MyInput>
              </StyledComponents.SpecialContainer>

              <StyledComponents.SpecialContainer
                className="l-flex flex-column"
                style={{ width: "260px" }}>
                <StyledComponents.MyP
                  fontSize="14px"
                  className="mb10 font-weight-bold text-purple40 ml8">
                  Orden de Compra:
                </StyledComponents.MyP>
                <StyledComponents.MyInput
                  value={purchaseOrder}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      setpurchaseOrder(value);
                    }
                  }}
                  borderRadius="40px"
                  textAlign="left"
                  className={"bg-grey130"}
                  margin="0px 4px 0px"
                  placeholder="# Orden de Compra"
                  fontSize="12px"
                  width="260px"
                  padding="10px"
                  disabled={isAlredyProcessed && isActionSuccess}></StyledComponents.MyInput>
              </StyledComponents.SpecialContainer>
            </StyledComponents.SpecialContainer>

            {step.stepOneisComplete && (
              <Assets.IconCheck color="var(--green50)" colorCheck="black" size="32" />
            )}
          </StyledComponents.SpecialContainer>
          <Layouts.Border margin="30px 0" border="1px dashed var(--purple40)" />
        </StyledComponents.SpecialContainer>

        {/* Step 02 */}
        <StyledComponents.SpecialContainer
          className={`l-flex flex-column ${step.stepOneisComplete ? "" : "disabled"}`}>
          <StyledComponents.MyP fontSize="14px" className="mb10 font-weight-bold text-purple40">
            2. Cargar Archivo PDF
          </StyledComponents.MyP>
          <StyledComponents.SpecialContainer
            className="d-flex align-items-center"
            style={{ gap: "20px" }}>
            <StyledComponents.SpecialContainer className="d-flex align-items-center">
              <InputFile
                disabled={
                  isAlredyProcessed && isActionSuccess
                    ? true
                    : step.stepOneisComplete
                    ? false
                    : true
                }
                onChange={handlerChangeInputFile}
                width={"100px"}
                fileName={fileNames}
              />

              <StyledComponents.SpecialContainer
                className="d-flex align-items-center bg-grey130 "
                gridGap="4px"
                fontSize="12px"
                style={{ color: "black" }}
                borderRadius="4px"
                padding="10px"
                height="32px"
                width="915px">
                <span>{fileNames.join(", ")}</span>
              </StyledComponents.SpecialContainer>
            </StyledComponents.SpecialContainer>
            <div style={{ width: "32px" }}>
              {step.stepTwoisComplete && (
                <Assets.IconCheck color="var(--green50)" colorCheck="black" size="32px" />
              )}
              {viewError && !step.stepTwoisComplete && (
                <Assets.IconError color="var(--red70)" fill="black" size="32px" />
              )}
            </div>
          </StyledComponents.SpecialContainer>

          <Layouts.Border margin="30px 0" border="1px dashed var(--purple40)" />
        </StyledComponents.SpecialContainer>
        {/* Step 03 */}
        <StyledComponents.SpecialContainer className={step.stepTwoisComplete ? "" : "disabled"}>
          <StyledComponents.MyP fontSize="14px" className="mb10 font-weight-bold text-purple40">
            3. Procesar PDF para Digitalización
          </StyledComponents.MyP>
          <StyledComponents.SpecialContainer className="d-flex align-items-center justify-content-between">
            <StyledComponents.MyButton
              width="152px"
              height={"28px"}
              padding="0 20px"
              margin="0"
              className={`${
                isAlredyProcessed && isActionSuccess
                  ? "btn-disabled"
                  : step.stepTwoisComplete
                  ? false
                  : "btn-disabled"
              } bg-green50 text-grey80 font-weight-bold`}
              disabled={
                isAlredyProcessed && isActionSuccess ? true : step.stepTwoisComplete ? false : true
              }
              onClick={(e) => handleClickProcess(e)}>
              <span className="mr10">Procesar PDF</span>
              <Assets.IconUpload />
            </StyledComponents.MyButton>
            <div style={{ width: "32px" }}>
              {step.stepThreeIsComplete &&
                (isActionSuccess ? (
                  <Assets.IconCheck color="var(--green50)" size="32px" colorCheck="black" />
                ) : (
                  <Assets.IconError color="var(--red70)" fill="black" size="32px" />
                ))}
            </div>
          </StyledComponents.SpecialContainer>
        </StyledComponents.SpecialContainer>
      </Layouts.Card>
      <Container>
        {viewError && (
          <div style={{ fontSize: "12px" }} className={`text-danger`}>
            <StyledComponents.MyP
              fontSize="14px"
              className="mt20 font-weight-bold"
              style={{ color: "black" }}>
              Resumen:
            </StyledComponents.MyP>
            {renderErrors(messageError)}
          </div>
        )}
        {isAlredyProcessed && !viewError && (
          <StyledComponents.SpecialContainer>
            <StyledComponents.MyP
              fontSize="14px"
              className="mt20 font-weight-bold"
              style={{ color: "black" }}>
              Resumen:
            </StyledComponents.MyP>
            {isActionSuccess ? (
              <div style={{ fontSize: "12px" }}>
                {digitalization_action_success?.message}
                {digitalization_action_success?.notifications &&
                  digitalization_action_success.notifications.length > 0 &&
                  renderNotifications(digitalization_action_success?.notifications)}
              </div>
            ) : (
              <div style={{ fontSize: "12px" }} className={`text-danger`}>
                El documento NO ha sido procesado de manera correcta debido a lo siguiente:
                {renderErrors(error)}
              </div>
            )}

            <StyledComponents.MyButton
              maxWidth={"160px"}
              height={"28px"}
              className={`mt20 d-flex align-items-center justify-content-center bg-purple20 text-white font-weight-bold`}
              padding={"6px"}
              style={{ fontSize: "12px" }}
              onClick={handlerOnClickProcessOther}>
              <div className={"mr10"}>Procesar otro PDF </div>
              <Assets.IconArrowClockWise />
            </StyledComponents.MyButton>
          </StyledComponents.SpecialContainer>
        )}
      </Container>
    </>
  );
};
